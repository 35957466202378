import BaseService from "./BaseService";
import Restaurant from "../../../foodworks-models/Restaurant";
import Menu from "../../../foodworks-models/Menu";
import ServiceContext from './ServiceContext';
import _ from "lodash"

export default class RestaurantService extends BaseService {

    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_PLACE || "";
    }

    //
    public async get(id: string): Promise<Restaurant> {
        const url = `restaurant/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<Restaurant>> {
        const result = await (await this.api(this._apiUrl)).get('restaurants');
        return result.data.Items;
    }
    //
    public async getHierarch(): Promise<Array<Restaurant>> {
        const result = await (await this.api(this._apiUrl)).get('restaurant/gethierarchy');
        return result.data;        
        // const restaurants = await this.gets();
        // for (let index = 0; index < restaurants.length; index++) {
        //     const restaurant = restaurants[index];
        //     const menus = await ServiceContext.MenuService.getHierarch(restaurant?.id)
        //     restaurant.children = menus;
        // }
        // return restaurants;
    }
    //
    public async create(restaurant: Restaurant): Promise<Restaurant> {
        const result = await (await this.api(this._apiUrl)).post('restaurant', { Item: restaurant });
        return result.data;
    }
    //
    public async update(restaurant: Restaurant): Promise<Restaurant> {
        const result = await (await this.api(this._apiUrl)).patch('restaurant', { Item: restaurant });
        return result.data;
    }
    //
    public async delete(restaurant: Restaurant): Promise<Restaurant> {
        const url = `/restaurant/t/${restaurant.createdAt}/${restaurant.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
    //
    public async deleteMenu(record: Menu): Promise<Restaurant> {
        const restaurant = await this.get(record.idRestaurant);
        const index = _.findIndex(restaurant.children, function (o: Menu) { return o.id == record.id; });
        if (index > -1) {
            restaurant.children.splice(index, 1);
        }

        return await this.update(restaurant);
    }
    //
    public async getRestaurants(idLocation: string): Promise<Array<Restaurant>> {
        const location = await ServiceContext.LocationService.get(idLocation);
        const result: Array<Restaurant> = [];

        _.each(location.restaurants, async (restaurant: string) => {
            result.push(await this.get(restaurant));
        })

        return result;
    }
}