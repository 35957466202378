import BaseService from "./BaseService";
import Schedule from "../../../foodworks-models/Schedule";
import ScheduleFilter from "../../../foodworks-models/ScheduleFilter";
import MenuInfo from "../../../foodworks-models/MenuInfo";

import _ from "lodash"
import ServiceContext from './ServiceContext';
import MenuItem from '../../../foodworks-models/MenuItem';
import MealPeriod from '../../../foodworks-models/MealPeriod';
import Category from '../../../foodworks-models/Category';

export default class ScheduleService extends BaseService {
    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_SCHEDULE || "";
    }
    //
    public async get(id: string): Promise<Schedule> {
        const url = `schedule/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<Schedule>> {
        const result = await (await this.api(this._apiUrl)).get('schedules');
        return result.data.Items;
    }
    //
    public async create(schedule: Schedule): Promise<Schedule> {
        const result = await (await this.api(this._apiUrl)).post('schedule', { Item: schedule });
        return result.data;
    }
    //
    public async update(schedule: Schedule): Promise<Schedule> {
        const result = await (await this.api(this._apiUrl)).patch('schedule', { Item: schedule });
        return result.data;
    }
    //
    public async delete(schedule: Schedule): Promise<Schedule> {
        const url = `/schedule/t/${schedule.createdAt}/${schedule.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
    //
    public async save(schedule: Schedule): Promise<Schedule> {
        if (!schedule.id) {
            return this.create(schedule);
        } else {
            return this.update(schedule);
        }
    }
    //
    public async getByFilter(filter: ScheduleFilter): Promise<Array<Schedule>> {
        const result = await (await this.api(this._apiUrl)).post('schedule/filter', { Item: filter });
        return result.data;
    }
    //
    public async getHierarchy(idCity: string, idLocation: string, timestamp: number): Promise<Array<MenuInfo>> {
        const menus: Array<MenuInfo> = [];
        const result = await (await this.api(this._apiUrl)).post('schedule/gethierarchy', {
            Item: {
                idCity,
                idLocation,
                timestamp
            }
        });
        return result.data;
    }
}

