import User from '../../../foodworks-models/User';
import BaseServiceBase from "./BaseService";

export default class AuthService extends BaseServiceBase {
    //
    private _apiUrl: string

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_BASE || "";
    }

    public async login(user: User): Promise<User> {
        return (await (await this.api(this._apiUrl)).post('auth/login', { Item: user })).data;
    }
}