






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  private title: string;

  constructor() {
    super();
    this.title = process.env.VUE_APP_TITLE;
  }

  created() {
    document.title = this.title;
  }
}
