
import axios, { AxiosInstance } from "axios"
import AsyncStorageHelper from "../helpers/AsyncStorageHelper";
import config from "../configs/constants";

export default class BaseService {
    //
    api = async (apiUrl: string): Promise<AxiosInstance> => {
        const token = await AsyncStorageHelper.getToken();

        const instance: AxiosInstance = axios.create({
            baseURL: apiUrl,
            timeout: 10000,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": token
            }
        });
        //request
        instance.interceptors.request.use(function (config) {
            //console.log(config);
            // Do something before request is sent
            return config;
        }, function (error) {
            alert('Erro');
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        instance.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            return Promise.reject(error);
        });

        return instance;
    }
}