import BaseService from "./BaseService";
import MenuItem from "../../../foodworks-models/MenuItem";

export default class MenuItemService extends BaseService {

    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_MENU || "";
    }
    //
    public async get(id: string): Promise<MenuItem> {
        const url = `menuitem/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(idMenu: string): Promise<Array<MenuItem>> {
        const url = `menuitems/s/${idMenu}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data.Items;
    }
    //
    public async create(menuitem: MenuItem): Promise<MenuItem> {
        const result = await (await this.api(this._apiUrl)).post('menuitem', { Item: menuitem });
        return result.data;
    }
    //
    public async update(menuitem: MenuItem): Promise<MenuItem> {
        const result = await (await this.api(this._apiUrl)).patch('menuitem', { Item: menuitem });
        return result.data;
    }
    //
    public async delete(menuitem: MenuItem): Promise<MenuItem> {
        const url = `/menuitem/t/${menuitem.createdAt}/${menuitem.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
    //
    public async save(menuitem: MenuItem): Promise<MenuItem> {
        if (!menuitem.id) {
            return await this.create(menuitem);
        } else {
            return await this.update(menuitem);
        }
    }
}