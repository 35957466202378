import BaseService from "./BaseService";
import City from "../../../foodworks-models/City";
import Location from "../../../foodworks-models/Location";
import _ from "lodash";
import ServiceContext from './ServiceContext';

export default class CityService extends BaseService {

    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_PLACE || "";
    }

    public async get(id: string): Promise<City> {
        const url = `city/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<City>> {
        const result = await (await this.api(this._apiUrl)).get('cities');
        const cities = result.data.Items;
        return cities;
    }
    //
    public async getsHierarchy(): Promise<Array<City>> {
        // const result = await (await this.api(this._apiUrl)).get('city/gethierarchy');
        // return result.data;
        // //city
        const cities = await this.gets();
        for (let index = 0; index < cities.length; index++) {
            const city = cities[index];
            //location
            city.children = await ServiceContext.LocationService.gets(city.id) ; //locations            
            _.each(city.children, async (location: Location) => {
                location.children = [];
                //restaurant
                _.each(location.restaurants, async (idRestaurant: string) => {
                    const restaurant = await ServiceContext.RestaurantService.get(idRestaurant);
                    restaurant.children = await ServiceContext.MenuService.gets(idRestaurant);

                    location.children.push(restaurant);
                })
            })
        }
        return cities;
    }
    //
    public async getsWithLocation(): Promise<Array<City>> {
        //city
        const cities = await this.gets();
        for (let index = 0; index < cities.length; index++) {
            const city = cities[index];
            //location
            city.children = await ServiceContext.LocationService.gets(city.id); //locations            
        }
        return cities;
    }
    //
    public async create(record: City): Promise<City> {
        const result = await (await this.api(this._apiUrl)).post('city', { Item: record });
        return result.data;
    }
    //
    public async update(record: City): Promise<City> {
        const result = await (await this.api(this._apiUrl)).patch('city', { Item: record });
        return result.data;
    }
    //
    // public async updateRestaurant(record: Location): Promise<Location> {
    //     const city = await this.get(record.idCity);
    //     //updating location;
    //     const index = _.findIndex(city.children, function (o: Location) { return o.id == record.id; });
    //     if (index > -1) {
    //         record.updatedAt = moment().unix();
    //         city.children[index] = record;
    //     }
    // }
    // //
    // public async updateLocation(record: Location): Promise<City> {
    //     const city = await this.get(record.idCity);
    //     if (city.children == undefined) {
    //         city.children = [];
    //     }

    //     if (record.id == undefined) {
    //         record.id = uuid.v4()
    //         record.createdAt = moment().unix();
    //         record.type = "location"
    //         city.children.push(record);
    //     } else {
    //         if (city.children) {
    //             const index = _.findIndex(city.children, function (o: Location) { return o.id == record.id; });
    //             if (index > -1) {
    //                 record.updatedAt = moment().unix();
    //                 city.children[index] = record;
    //             }
    //         }
    //     }
    //     return await this.update(city);
    // }

    //
    // public async deleteLocation(record: Location): Promise<City> {
    //     // const city = await this.get(record.cityId);
    //     // const index = _.findIndex(city.children, function (o: Location) { return o.id == record.id; });
    //     // if (index > -1) {
    //     //     city.children.splice(index, 1);
    //     // }

    //     // return await this.update(city);
    // }
    //
    public async delete(record: City): Promise<City> {
        const url = `/city/t/${record.createdAt}/${record.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
    //
    // public async getLocation(idCity: string, idLocation: string): Promise<Location> {
    //     let location: Location = {};
    //     const locations = (await ServiceContext.CityService.get(idCity)).children;
    //     const result: Array<Location> = _.filter(
    //         locations,
    //         (location: Location) => {
    //             return location.id == idLocation;
    //         }
    //     );

    //     if (result && result.length > 0) {
    //         location = result[0];
    //         // _.each(result[0].restaurants, async (restaurant: string) => {
    //         //     this.restaurants.push(await ServiceContext.RestaurantService.get(restaurant));
    //         // })
    //     }

    //     return location;
    // }
}