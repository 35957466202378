import AsyncStorageHelper from "../helpers/AsyncStorageHelper";
import User from "../../../foodworks-models/User";
import BaseServiceBase from "./BaseService";

export default class UserService extends BaseServiceBase {
    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_USER || "";
    }
    //
    public async getCurrentUser(): Promise<User> {
        const user: User = await AsyncStorageHelper.getActiveUser();
        return await this.get(user.id);
    }
    public async get(id: string): Promise<User> {
        const url = `user/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async getByEmail(email?: string): Promise<User> {
        const url = `user/getbyemail`;
        const result = await (await this.api(this._apiUrl)).post(url, { Item: { email: email } });
        return result.data;
    }
    //
    public async getByPhone(phone?: string): Promise<User> {
        const url = `user/getbyphone`;
        const result = await (await this.api(this._apiUrl)).post(url, { Item: { phone: phone } });
        return result.data;
    }
    //
    public async gets(): Promise<Array<User>> {
        const result = await (await this.api(this._apiUrl)).get('users');
        return result.data.Items;
    }
    //
    public async create(user: User): Promise<User> {
        const result = await (await this.api(this._apiUrl)).post('user', { Item: user });
        return result.data;
    }
    //
    public async createUpdate(user: User): Promise<User> {
        const result = await (await this.api(this._apiUrl)).post('user-add-update', { Item: user });
        return result.data;
    }
    //
    public async update(user: User): Promise<User> {
        const result = await (await this.api(this._apiUrl)).patch('user', { Item: user });
        return result.data;
    }
    //
    public async delete(user: User): Promise<User> {
        const url = `/user/t/${user.createdAt}/${user.id}`;
        console.log(url);
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}