import BaseService from "./BaseService";
import Subscription from "../../../foodworks-models/Subscription";

export default class SubscriptionService extends BaseService {

    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_SUBSCRIPTION || "";
    }
  
    //
    public async get(id: string): Promise<Subscription> {
        const url = `subscription/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<Subscription>> {
        const result = await (await this.api(this._apiUrl)).get('subscriptions');
        return result.data.Items;
    }
    //
    public async create(record: Subscription): Promise<Subscription> {
        const result = await(await this.api(this._apiUrl)).post('subscription', { Item: record });
        return result.data;
    }
    //
    public async update(record: Subscription): Promise<Subscription> {
        const result = await (await this.api(this._apiUrl)).patch('subscription', { Item: record });
        return result.data;
    }
    //
    public async delete(record: Subscription): Promise<Subscription> {
        const url = `/subscription/t/${record.createdAt}/${record.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}