import BaseService from "./BaseService";
import Category from "../../../foodworks-models/Category";

export default class CategoryService extends BaseService {

    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_BASE || "";
    }
  
    //
    public async get(id: string): Promise<Category> {
        const url = `category/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<Category>> {
        const result = await (await this.api(this._apiUrl)).get('categories');
        return result.data.Items;
    }
    //
    public async create(category: Category): Promise<Category> {
        const result = await(await this.api(this._apiUrl)).post('category', { Item: category });
        return result.data;
    }
    //
    public async update(category: Category): Promise<Category> {
        const result = await (await this.api(this._apiUrl)).patch('category', { Item: category });
        return result.data;
    }
    //
    public async delete(category: Category): Promise<Category> {
        const url = `/category/t/${category.createdAt}/${category.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}