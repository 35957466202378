import User from '../../../foodworks-models/User';
import localforage from 'localforage';

export default class AsyncStorageHelper  {
    static async getToken() : Promise<string> {
        return await this.getItem('token') as string ;
    }
    //
    static async saveToken(value?: string) {
        await this.saveItem('token', value);
    }
    //
    static async clearToken() {
        await this.saveItem('token', null);
    }
    //
    static async getActiveUser(): Promise<User> {
        let result: User = null;
        const json = await this.getItem('user');

        console.log("json", json);
        if(json) {
            result = {};
            Object.assign(result, JSON.parse(json));
        }
        
        return result;
    }
    //
    static async saveActiveUser(value: User) {
        await this.saveItem('user', JSON.stringify(value));
    }
    //
    static async clearActiveUser() {
        await this.removeItem('user');
    }
    //
    static async saveEndpointArn(value: string) {
        await this.saveItem('EndpointArn', value);
    }
    //
    static async getEndpointArn() {
        return await this.getItem('EndpointArn');
    }
    //
    static async saveItem(item: string, selectedValue: any) {
        return localforage.setItem(item, selectedValue);
    }
    //
    static async getItem(item: string) {
        return localforage.getItem(item);
    }
    //
    static async removeItem(item: string) {
        return await localforage.removeItem(item);
    }
}
