import BaseService from "./BaseService";
import Menu from "../../../foodworks-models/Menu";
import ServiceContext from './ServiceContext';
import _ from "lodash";
import MenuItem from '../../../foodworks-models/MenuItem';


export default class MenuService extends BaseService {
    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_MENU || "";
    }    
    //
    public async get(id: string): Promise<Menu> {
        const url = `menu/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(idRestaurant: string): Promise<Array<Menu>> {
        const url = `menus/s/${idRestaurant}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data.Items;
    }
    //
    public async getHierarch(idRestaurant: string): Promise<Array<Menu>> {
        const menus = await this.gets(idRestaurant);
        for (let index = 0; index < menus.length; index++) {
            const menu = menus[index];
            menu.children = await ServiceContext.MenuItemService.gets(menu.id);
        }
        return menus;
    }
    //
    public async create(menu: Menu): Promise<Menu> {
        const result = await (await this.api(this._apiUrl)).post('menu', { Item: menu });
        return result.data;
    }
    //
    public async update(menu: Menu): Promise<Menu> {
        const result = await (await this.api(this._apiUrl)).patch('menu', { Item: menu });
        return result.data;
    }
    //
    public async save(menu: Menu): Promise<Menu> {
        if (!menu.id) {
            return this.create(menu);
        } else {
            return this.update(menu);
        }
    }
    //
    public async clone(idMenu: string, name: string): Promise<Menu> {
       let menu: Menu = await this.get(idMenu);
       menu.name = name;

       menu = await this.create(menu);
       //
       const menuItems: Array<MenuItem> = await ServiceContext.MenuItemService.gets(idMenu);
       _.each(menuItems, async (menuItem: MenuItem) => {
            menuItem.idMenu = menu.id;
            await ServiceContext.MenuItemService.create(menuItem);
       });

       return menu;
    }
    //
    public async delete(menu: Menu): Promise<Menu> {
        const url = `/menu/t/${menu.createdAt}/${menu.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}