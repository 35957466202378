




























































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ServiceContext from "../../../foodworks-admin/src/services/ServiceContext";
import City from "../../../foodworks-models/City";
import Location from "../../../foodworks-models/Location";
import DaySelection from "./../models/DaySelection";
import MenuInfo from "../../../foodworks-models/MenuInfo";
import MealPeriod from "../../../foodworks-models/MealPeriod";
import Category from "../../../foodworks-models/Category";

import moment from "moment";
import _ from "lodash";

@Component({
  components: {}
})
export default class Index extends Vue {
  private cities: Array<any>;
  private currentCity: any;
  private currentLocation: any;
  private days: Array<any>;
  private currentDayIndex: number;
  private currentDay: any;
  private schedules: Array<any>;
  private isLoading: boolean;
  private bucketPath: string;

  constructor() {
    super();
    this.cities = [];
    this.currentCity = null;
    this.currentLocation = null;
    this.days = [];
    this.currentDayIndex = -1;
    this.currentDay = null;
    this.schedules = null;
    this.isLoading = false;
    this.bucketPath = "";
  }

  async mounted() {
    await this.fectchCities();
    this.mountSlider();
    this.bucketPath = process.env.VUE_APP_BUCKET_PATH
      ? process.env.VUE_APP_BUCKET_PATH
      : "-";
  }

  public mountSlider() {
    // const daysInCurrentMonth: number = moment().daysInMonth(); 
    // const daysInNextMonth: number = moment().add(1, 'M').daysInMonth(); 
    const startOfMonth = moment().startOf("month");
    const currentDay = moment()
      .startOf("day")
      .unix();
    let currentDayIndex = -1;

    for (let index = 0; index < 15; index++) {
      const day = moment(startOfMonth).add(moment().day() + index, "days");
      const timestamp = moment(day).unix();

      if (currentDay === timestamp) {
        currentDayIndex = index;
      }

      this.days.push({
        name: day.format("ddd. M/D"),
        timestamp
      });
    }

    this.currentDayIndex = currentDayIndex;
  }

  @Watch("currentDayIndex")
  public async onCurrentDayIndexHasChanged() {
    this.currentDay = this.days[this.currentDayIndex];
    await this.fetchSchedule(this.currentDay.timestamp);
  }

  @Watch("currentLocation")
  public async onCurrentLocationHasChanged() {
    if (this.currentDay && this.currentDay.timestamp)
      await this.fetchSchedule(this.currentDay.timestamp);
  }

  public async fectchCities() {
    this.cities = await ServiceContext.CityService.getsWithLocation();
  }

  public hasCategoriesWithMenuItems(mealperiod: MealPeriod) {
    let result = false;
    if (mealperiod.categories) {
      _.each(mealperiod.categories, (category: Category) => {
        if (category.menuItems && category.menuItems.length > 0) {
          result = true;
          return false; //exit
        }
      });
    }
    return result;
  }

  public categoryHasMenuItems(category: Category) {
    return category.menuItems && category.menuItems.length > 0;
  }

  public async fetchSchedule(timestamp: number) {
    if (this.currentCity && this.currentLocation && timestamp) {
      try {
        this.isLoading = true;
        this.schedules = await ServiceContext.ScheduleService.getHierarchy(
          this.currentCity.id,
          this.currentLocation.id,
          timestamp
        );
      } catch (error) {
        this.schedules = [];
      } finally {
        this.isLoading = false;
      }
    }
  }
}
