import BaseService from "./BaseService";
import MealPeriod from "../../../foodworks-models/MealPeriod";

export default class MealPeriodService extends BaseService {
    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_BASE || "";
    }

    //
    public async get(id: string): Promise<MealPeriod> {
        const url = `mealperiod/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(): Promise<Array<MealPeriod>> {
        const result = await (await this.api(this._apiUrl)).get('mealperiods');
        return result.data.Items;
    }
    //
    public async create(mealperiod: MealPeriod): Promise<MealPeriod> {
        const result = await(await this.api(this._apiUrl)).post('mealperiod', { Item: mealperiod });
        return result.data;
    }
    //
    public async update(mealperiod: MealPeriod): Promise<MealPeriod> {
        const result = await (await this.api(this._apiUrl)).patch('mealperiod', { Item: mealperiod });
        return result.data;
    }
    //
    public async delete(mealperiod: MealPeriod): Promise<MealPeriod> {
        const url = `/mealperiod/t/${mealperiod.createdAt}/${mealperiod.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}