import BaseService from "./BaseService";
import LocationEntity from "../../../foodworks-models/Location";

export default class LocationService extends BaseService {
    private _apiUrl: string;

    constructor() {
        super();
        this._apiUrl = process.env.VUE_APP_API_URL_SERVICE_PLACE || "";
    }

    //
    public async get(id: string): Promise<LocationEntity> {
        const url = `location/n/${id}`;
        const result = await (await this.api(this._apiUrl)).get(url);
        return result.data;
    }
    //
    public async gets(idCity: string): Promise<Array<LocationEntity>> {
        const url = `locations/s/${idCity}`;
        const result = await (await this.api(this._apiUrl)).get(url); ``
        return result.data.Items;
    }
    //
    public async create(location: LocationEntity): Promise<LocationEntity> {
        const result = await (await this.api(this._apiUrl)).post('location', { Item: location });
        return result.data;
    }
    //
    public async update(location: LocationEntity): Promise<LocationEntity> {
        const result = await (await this.api(this._apiUrl)).patch('location', { Item: location });
        return result.data;
    }
    //
    public async save(location: LocationEntity): Promise<LocationEntity> {
        if (!location.id) {
            return this.create(location);
        } else {
            return this.update(location);
        }
    }
    //
    public async delete(location: LocationEntity): Promise<LocationEntity> {
        const url = `/location/t/${location.createdAt}/${location.id}`;
        const result = await (await this.api(this._apiUrl)).delete(url);
        return result.data;
    }
}