import UserService from './UserService';
import AuthService from './AuthService';
import CategoryService from './CategoryService';
import CityService from './CityService';
import RestaurantService from './RestaurantService';
import MenuService from './MenuService';
import MealPeriodService from './MealPeriodService';
import ScheduleService from './ScheduleService';
import LocationService from './LocationService';
import MenuItemService from './MenuItemService';
import SubscriptionService from './SubscriptionService';

export default class ServiceContext {

    private static _userService?: UserService;
    private static _authService?: AuthService;
    private static _categoryService?: CategoryService;
    private static _cityService?: CityService;
    private static _locationService?: LocationService;
    private static _restaurantService?: RestaurantService;
    private static _menuService?: MenuService;
    private static _menuItemService?: MenuItemService;
    private static _mealPeriodService?: MealPeriodService;
    private static _scheduleService?: ScheduleService;
    private static _subscriptionService: SubscriptionService

    public static get SubscriptionService(): SubscriptionService {
        if (!this._subscriptionService)
            this._subscriptionService = new SubscriptionService();

        return this._subscriptionService;
    }

    public static get MenuItemService(): MenuItemService {
        if (!this._menuItemService)
            this._menuItemService = new MenuItemService();

        return this._menuItemService;
    }

    public static get LocationService(): LocationService {
        if (!this._locationService)
            this._locationService = new LocationService();

        return this._locationService;
    }

    public static get ScheduleService(): ScheduleService {
        if (!this._scheduleService)
            this._scheduleService = new ScheduleService();

        return this._scheduleService;
    }

    public static get UserService(): UserService {
        if (!this._userService)
            this._userService = new UserService();

        return this._userService;
    }

    public static get RestaurantService(): RestaurantService {
        if (!this._restaurantService)
            this._restaurantService = new RestaurantService();

        return this._restaurantService;
    }

    public static get CategoryService(): CategoryService {
        if (!this._categoryService)
            this._categoryService = new CategoryService();

        return this._categoryService;
    }

    public static get AuthService(): AuthService {
        if (!this._authService)
            this._authService = new AuthService();

        return this._authService;
    }

    public static get CityService(): CityService {
        if (!this._cityService)
            this._cityService = new CityService();

        return this._cityService;
    }

    public static get MenuService(): MenuService {
        if (!this._menuService)
            this._menuService = new MenuService();

        return this._menuService;
    }

    public static get MealPeriodService(): MealPeriodService {
        if (!this._mealPeriodService)
            this._mealPeriodService = new MealPeriodService();

        return this._mealPeriodService;
    }
}